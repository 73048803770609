<template>
  <div class="course-wrapper course_power"><!--v-loading="loading"-->
		<div class="course-wrapper-title">
			<span>课程权限</span>
		</div>
    <div class="course-wrapper-body">
      <div class="course-wrapper-function">
        <div class="function-left">
          <el-row style="width: 100%;">
            <el-col :span="4">
              <el-input placeholder="课程名称" style="width: calc(100% - 1.04167vw);" v-model="searchFrom.courseName"></el-input>
            </el-col>
            <el-col :span="4">
              <el-select style="width: calc(100% - 1.04167vw);" v-model="searchFrom.courseTheme" clearable placeholder="全部课程系列">
                <el-option v-for="item in themeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
              <!-- <el-input placeholder="全部课程系列" class="input-search" v-model="searchFrom.courseTitleAll"></el-input> -->
            </el-col>
            <el-col :span="6">
              <el-button @click="handleButton('search')" type="primary" icon="el-icon-search">查询</el-button>
              <el-button @click="handleButton('reset')" icon="el-icon-refresh-left">重置</el-button>
              <el-button @click="tableHandleButton('group')" type="primary" v-if="isSetting">批量配置</el-button>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-table :data="tableData" ref="table" class="course_power_table" border>
        <el-table-column
          type="selection"
          width="55"
          align="center"
        >
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="courseName"
          label="课程名称"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.courseName }}</div>
              <div class="handleData">{{ scope.row.courseName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="courseThemeName"
          label="课程系列"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="courseClassifyName"
          label="课程分类"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.courseClassifyName }}</div>
              <div class="handleData">{{ scope.row.courseClassifyName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="courseFieldName"
          label="课程领域"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.courseFieldName }}</div>
              <div class="handleData">{{ scope.row.courseFieldName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="eduDeptName"
          label="权限信息"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top" popper-class="customPopper" effect="light">
              <div class="allData" slot="content">{{ scope.row.eduDeptName }}</div>
              <div class="handleData">{{ scope.row.eduDeptName }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="prop"
          label="操作"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-button @click="tableHandleButton(scope)" type="text" v-if="isSetting">配置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="pagination.total > 0" class="l-pages">
        <div class="total-num">
          显示第{{ showNum }}条记录，共{{ pagination.total }}条记录
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.pageSizes"
          :total="pagination.total"
          layout="prev, pager, next, sizes"
        ></el-pagination>
      </div>
    </div>
    <DialogCoursePower :treeData="treeData" :handleData="treeDatas" @tree="tree" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogCoursePower from "./DialogCoursePower"
import { getCourseTypeName, getCourseFieldName, getCourseThemeName } from "@/utils/getDicName";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  name: 'coursePower',
  components: {DialogCoursePower},
  data() {
    return {
      // loading: true,
      pageLoading: null,
      tableData: [],
      searchFrom: {
        courseName: '',
        courseTheme: '',
        // courseTitleAll: ''
      },
      pagination: {
        current: 1,
        pageSizes: [10, 20, 50, 100, 500],
        size: 10,
        total: 0,
      },
      treeData: [],
      treeDatas: null,
      currentScope: null,

      isSetting: false,
      permissionButtonList: [],
    }
  },
  computed: {
    ...mapState({
      typeOptions: (state) => state.common.courseTypeList,
      areaOptions: (state) => state.common.courseFieldList,
      themeOptions: (state) => state.common.courseThemeList,
      versionOptions: (state) => state.common.courseVersionList,
      menuList: (state) => state.common.menuList,
    }),
    showNum() {
      let start = (this.pagination.current - 1) * this.pagination.size + 1;
      let end =
        this.pagination.current * this.pagination.size > this.pagination.total
          ? this.pagination.total
          : this.pagination.current * this.pagination.size;
      return this.pagination.total === 0
        ? this.pagination.total
        : `${start}-${end}`;
    },
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isSetting = val.indexOf("coursePower:config") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created(){
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  mounted() {
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: "loadingStyle"
    });
    this.getPowerList();
    setTimeout(() => {
      this.getCoursePower();
    },300)
  },
  methods: {
    getPermissionButton,
    getCourseTypeName,
    getCourseFieldName,
    getCourseThemeName,
    async getPowerList() {
      await this.$api.getDept( {officeNo: 'C0053'})
        .then(result=>{
          let {code,data, msg} = result;
          if (code) {
            this.$message.error(msg);
            return;
          }
          this.treeData = data.data;
          return data.data;
        })
        .catch(error=>{
          this.$message.error(error.message);
        })
    },
    async getCoursePower() {
      let { courseName, courseTheme } = this.searchFrom;
      let {current, size} = this.pagination;
      let data = {
        courseName,
        courseTheme,
        current,
        size
      };
      if (!courseName) delete data.courseName;
      await this.$api.getCoursePower(data)
        .then(result=>{
          this.pageLoading.close()
          let {code, data, msg} = result;
          this.loading = false;
          if (code) {
            this.$message.error(msg);
          }
          let {records, total} = data.data;
          records.map(item=>{
            if(item.courseClassify){
              let courseClassifyList = item.courseClassify.split(",")
              let ccourseClassifyNameList = []
              courseClassifyList.map((info) => {
                ccourseClassifyNameList.push(this.getCourseTypeName(info))
              })
              item.courseClassifyName = ccourseClassifyNameList.join(",")
            }
            if(item.courseField){
              let courseFieldList = item.courseField.split(",")
              let courseFieldNameList = []
              courseFieldList.map((info) => {
                courseFieldNameList.push(this.getCourseFieldName(info))
              })
              item.courseFieldName = courseFieldNameList.join(",")
            }
            let courseThemeName = this.getCourseThemeName(item.courseTheme)
            item.courseThemeName = courseThemeName
            let eduDeptName = [];
            let eduDeptId = []
            item.eduDeptList.forEach(item1=>{
              eduDeptName.push(item1.name);
              eduDeptId.push(item1.id)
            })
            item.eduDeptName = eduDeptName.join('|');
            item.eduDeptId = eduDeptId.sort().toString();
            if (item.eduDeptName.length > 1000) {
              item.eduDeptName = item.eduDeptName.substring(0, 1000) + '...';
            }
          })
          this.tableData = records;
          this.pagination.total = total;
        })
        .catch(error=>{
          // this.loading = false;
          this.pageLoading.close();
          this.$message.error(error.message);
        });
    },
    async updateCoursePower(params) {
      // this.loading = true;
      // this.pageLoading = this.$loading({
      //   lock: true,
      //   fullscreen: true,
      //   text: '操作中',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)',
      //   customClass: "loadingStyle"
      // });
      return await this.$api.updateCoursePower(params);
    },
    handleButton(handleName) {
      switch (handleName) {
        case 'search': {
          this.pagination.current = 1
          this.getCoursePower();
          break;
        }
        case 'reset': {
          this.pagination.current = 1
          this.searchFrom.courseName = '';
          this.searchFrom.courseTheme = ''
          this.getCoursePower();
        }
      }
    },
    tableHandleButton(scope) {
      let treeDatas = {
        treeList: JSON.parse(JSON.stringify(this.treeData)),
        groupList: null,
        scope: null,
      };
      this.treeDatas = null;
      if (scope === 'group') {
        this.currentScope = null;
        treeDatas.scope = 'group'
        let tableSelection = this.$refs.table.selection;
        if (!tableSelection.length) {
          this.$message.warning('批量选择不能为空');
          return;
        }
        console.log(tableSelection)
        let compareId = tableSelection[0].eduDeptId
        let compareNum = 1
        for(let i = 1; i < tableSelection.length; i++){
          if(compareId === tableSelection[i].eduDeptId){
            compareNum++
          }
        }
        if(compareNum != tableSelection.length){
          const h = this.$createElement;
          this.$confirm(
            h("p", null, [
              h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "您选择课程权限不同，批量修改将统一权限，是否继续？"),
              h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否继续，批量修改后，将统一权限。"),
            ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
          ).then(() => {
            this.$nextTick(()=>{
              this.treeDatas = treeDatas
            })
          }).catch(() => {});
        } else {
          if(tableSelection.length == 1){
            treeDatas.groupList = tableSelection[0]
          } else {
            treeDatas.groupList = null
          }
          this.$nextTick(()=>{
            console.log()
            this.treeDatas = treeDatas
          })
        }
      } else {
        this.currentScope = scope.row;
        treeDatas.scope = scope.row
        this.$nextTick(()=>{
          this.treeDatas = treeDatas
        })
      }
    },
    handleSizeChange(value) {
      this.pagination.current = 1
      this.pagination.size = value;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.getCoursePower();
    },
    handleCurrentChange(value) {
      this.pagination.current = value;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.getCoursePower();
    },
    async tree(data) {
      let {nodes, scope} = data;
      let _nodes = [];
      nodes = JSON.parse(JSON.stringify(nodes));
      nodes.forEach(item=>{
        let {label, id} = item;
        _nodes.push({
          officeLabel: label,
          deptId: id
        });
      });
      // this.loading = true
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '操作中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      if (scope === 'group') {
        let tableSelection = this.$refs.table.selection;
        let promises = [];
        tableSelection.forEach(item=>{
          promises.push(
            this.updateCoursePower({
              courseId: item.id,
              deptList: _nodes
            })
          )
        });
        await Promise.all(promises)
          .then(result=>{
            let code = 0;
            result.forEach(item=>{
              if (item.data.code) {
                console.log(item.data.code);
                code =item.data.code;
              }
            })
            if (code) {
              this.$message.error('权限操作更新失败');
              return;
            }
            this.$message.success('权限操作更新成功');
            this.getCoursePower();
          })
          .catch(error=>{
            this.$message.error(error.message);
          })
          .finally(_=>{
            this.pageLoading.close()
          })
      } else {
        await this.updateCoursePower({
          courseId: scope.id,
          deptList: _nodes
        })
        .then(result=>{
          let {code,msg} = result;
          if (code) {
            this.$message.error(msg);
            return;
          }
          this.$message.success('权限操作更新成功');
          this.getCoursePower();
        })
        .catch(error=>{
          this.$message.error(error.message);
        })
        .finally(_=>{
          this.pageLoading.close()
        })
      }



    }
  },
}
</script>

<style lang="scss">
$colorBorder: #597ef7;
$danger: #f3676c;
.course-wrapper.course_power {
  background: #fff;
  .course-wrapper-title {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px solid #d4d4d4;

    >span {
      position: relative;
      height: 42px;
      line-height: 42px;

      &:after {
        position: absolute;
        left: 50%;
        bottom: -20px;
        content: '';
        width: 25px;
        height: 4px;
        background: $colorBorder;
        transform: translateX(-50%);
      }
    }
  }
  .course-wrapper-body {
    width: calc(100% - 60px);
    padding: 20px 30px;
    overflow-x: auto;
    .course-wrapper-function {
      width: 100%;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .function-left,
      .function-right {
        display: flex;
        margin-bottom: 10px;
      }

      .function-left {
        width: 100%;
        .el-input {
          margin-right: 10px;
        }
      }

      // .el-input {
      //   width: 200px;
      //   &.input-select,
      //   &.input-search {
      //     width: 200px;
      //   }
      // }
    }

    .pagination {
      padding-top: 20px;
      .el-input__inner {
        height: 100%;
      }
    }

    .table-handle {
      .el-button--text {
        &.button-danger {
          color: $danger;
        }
      }
      .el-button--text + span {
        padding: 0 2px;
        color: #ebeef5;
      }
    }
  }


  .course-wrapper-footer {
    padding: 0 20px 30px;
    .footer-right {
      text-align: right;
    }
  }
}
</style>
