<template>
  <el-dialog
    title="课程数据权限"
    :visible.sync="dialogVisible"
    width="width"
    :destroy-on-close="true"
    :before-close="dialogBeforeClose">
    <div>
      <el-tree
        v-if="treeData && treeData.length > 0"
        :data="treeData"
        :props="defaultProps"
        :default-checked-keys="defaultCheckedKeys"
        :default-expanded-keys="defaultExpandedKeys"
        node-key="id"
        ref="tree"
        show-checkbox
        @check-change="treeChange"
      >
      </el-tree>
    </div>
    <div slot="footer">
      <el-button @click="dialogBeforeClose">取 消</el-button>
      <el-button type="primary" @click="dialogSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ['treeData', 'handleData'],
  data() {
    return {
      dialogVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tree: [],
      currentNodes: [],
      scope: null,
      defaultCheckedKeys: [],
      defaultExpandedKeys: []
    }
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
      this.$nextTick(() => {
        this.tree = null;
        this.currentNodes = [];
        this.defaultExpandedKeys = [];
        this.defaultCheckedKeys = [];
        this.$refs.tree.setCheckedNodes([]);
      })
    },
    dialogSubmit() {
      let data = {
        nodes: this.$refs.tree.getCheckedNodes(),
        scope: this.scope
      }
      if (!data.nodes.length) {
        this.$message.warning('课程数据权限设置选择不能为空');
        return;
      }
      this.$emit('tree', JSON.parse(JSON.stringify(data)));
      this.dialogBeforeClose()
    },
    treeChange() {
      let nodes = this.$refs.tree.getCheckedNodes();
      this.currentNodes = nodes;
    },
    treeCheckAll(is) {
      if (this.treeData && this.treeData[0]) {
        this.$refs.tree.setChecked(this.treeData[0].id, is, is);
      }
    }
  },
  watch: {
    handleData(data) {
      if (data) {
        let defaultCheckedKeys = [];
        let defaultExpandedKeys = [];
        let eduDeptList;
        this.scope = data.scope;
        this.dialogVisible = true;
        this.$nextTick(() => {
          if (data.scope === 'group') {
            if(data.groupList !== null){
              eduDeptList = data.groupList.eduDeptList;
            }
          } else {
            eduDeptList = data.scope.eduDeptList;
          }

          this.$nextTick(()=>{
            let isTreeData = this.treeData && this.treeData[0];
            if (isTreeData) {
              defaultExpandedKeys.push(this.treeData[0].id);
            }
            if (eduDeptList) {
              eduDeptList.forEach(item=> defaultCheckedKeys.push(item.id));
            }
            this.defaultExpandedKeys = defaultExpandedKeys;
            this.defaultCheckedKeys = defaultCheckedKeys;
          });
        })

      }
    }
  }
}
</script>

<style lang="scss">
</style>
